import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { syncLoadProgress } from '@cosmos/state';
import type { ModelWithLoadingStatus } from '@cosmos/types-common';
import { SearchCriteria } from '@esp/common/types';
import { CompaniesService } from '@esp/companies/data-access-api';
import type { DesignSearch } from '@esp/orders/types';

import { CompaniesDesignsActions } from '../../actions';

export interface CompaniesDesignsSearchStateModel
  extends ModelWithLoadingStatus {
  companyId: number;
  criteria: SearchCriteria;
  total: number;
  designs: DesignSearch[];
}

type ThisStateContext = StateContext<CompaniesDesignsSearchStateModel>;

@State<CompaniesDesignsSearchStateModel>({
  name: 'designSearch',
  defaults: {
    companyId: 1,
    criteria: new SearchCriteria(),
    designs: [],
    total: 0,
  },
})
@Injectable()
export class CompaniesDesignsSearchState {
  constructor(private readonly _companiesService: CompaniesService) {}

  @Action(CompaniesDesignsActions.PrepareCriteria)
  prepareCriteria(
    ctx: ThisStateContext,
    action: CompaniesDesignsActions.PrepareCriteria
  ) {
    ctx.patchState({ criteria: action.criteria });
  }

  @Action(CompaniesDesignsActions.SearchWithExistingCriteria)
  searchWithExistingCriteria(ctx: ThisStateContext) {
    return this._prepareDesigns(ctx);
  }

  @Action(CompaniesDesignsActions.Search)
  search(ctx: ThisStateContext, { criteria }: CompaniesDesignsActions.Search) {
    ctx.patchState({ criteria });
    return this._prepareDesigns(ctx);
  }

  private _prepareDesigns(ctx: ThisStateContext) {
    const { criteria } = ctx.getState();
    return this._companiesService.searchDesigns(criteria).pipe(
      syncLoadProgress(ctx),
      tap((response) =>
        ctx.patchState({
          designs: response.Results,
          total: response.ResultsTotal,
        })
      )
    );
  }
}
